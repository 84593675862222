<template>
  <title-bar v-if="email" :title="email.subject"/>
  <b-card v-if="email">
    <iframe :srcdoc="email.message" width="100%" height="800"></iframe>
  </b-card>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";

export default {
  components: {
    TitleBar
  },
  data() {
    return {
      email: null,
    };
  },
  created() {
    this.loadEmail();
  },
  methods: {
    loadEmail() {
      const id = this.$route.params.id;
      this.$Emails.getResource({id}).then((response) => {
        this.email = response.data;
      });
    },
  },
};
</script>
